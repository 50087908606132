import { Caption, Label, LabelVariants } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { SystemStyleObject } from '@styled-system/css';
import React, { Fragment } from 'react';

import { usePriceQuoteUnit } from './usePriceQuoteUnit';
import { Pricing } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { TotalPricePopover } from '@Core/prices/TotalPricePopover';
import { DiscountTooltip } from '@Pages/search-results/card/DiscountTooltip';

export type PriceProps = ClassNameProps & {
  pricing: Pick<
    Pricing,
    'discountPresentation' | 'pricingPresentation' | 'displayedPricingItems' | 'totalPaidAtHotel'
  >;
  localCurrency?: string;
  mayHaveFees?: boolean;
  strikeThroughStyles?: SystemStyleObject;
  perPersonVariant?: LabelVariants;
};

export const Prices: React.FC<PriceProps> = ({
  className,
  pricing,
  localCurrency,
  mayHaveFees,
  strikeThroughStyles,
  perPersonVariant = 'mediumbold',
}) => {
  const { priceQuoteUnit } = usePriceQuoteUnit();
  const { t } = useTranslation();
  const showTotalIncludingPaidAtHotel = useFeatureFlag('ShowTotalIncludingPaidAtHotel');
  const showFromPrices = useFeatureFlag('ShowFromPrices');

  if (!pricing.pricingPresentation) {
    return null;
  }

  return (
    <Fragment>
      {pricing.pricingPresentation.showStrikeThrough && (
        <div
          sx={{
            display: 'flex',
            ...strikeThroughStyles,
          }}
        >
          {pricing.pricingPresentation.discountPercentage && (
            <Caption
              variant="small"
              sx={{
                fontWeight: 'bold',
                color: 'actioncriticalDefault',
                marginRight: '4xs',
              }}
            >
              {pricing.pricingPresentation.discountPercentage}
            </Caption>
          )}
          <Caption
            variant="strikethrough"
            data-id="prices-previous"
          >
            <span
              sx={{
                color: 'textDimmedheavy',
                whiteSpace: 'nowrap',
              }}
            >
              {priceQuoteUnit === 'PER_PERSON' ? (
                <Fragment>
                  {pricing.pricingPresentation?.wasPerPerson} {t('priceQuoteUnit.perPersonShort')}
                </Fragment>
              ) : (
                pricing.pricingPresentation?.wasTotal
              )}
            </span>
          </Caption>
          <DiscountTooltip pricing={pricing} />
        </div>
      )}

      <div
        sx={{
          display: 'flex',
        }}
      >
        <div
          data-id="prices-total"
          className={className}
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
          }}
        >
          {showFromPrices && (
            <div
              sx={{
                display: 'flex',
                paddingBottom: '4xs',
                alignItems: 'baseline',
                columnGap: '4xs',
              }}
            >
              <Label variant="small">{t('pricing.from')}</Label>
            </div>
          )}
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
            }}
          >
            <Label
              variant="extralargebold"
              sx={{ marginLeft: '4xs', lineHeight: 1 }}
            >
              {priceQuoteUnit === 'PER_PERSON'
                ? pricing.pricingPresentation?.textPerPerson
                : pricing.pricingPresentation?.textTotal}
            </Label>
            {priceQuoteUnit === 'PER_PERSON' && (
              <Label
                variant={perPersonVariant}
                sx={{ marginLeft: '4xs', lineHeight: 1 }}
              >
                {t('priceQuoteUnit.perPersonShort')}
              </Label>
            )}
            {showTotalIncludingPaidAtHotel && (
              <span onClick={(e) => e.preventDefault()}>
                <TotalPricePopover
                  pricing={pricing}
                  localCurrency={localCurrency}
                  mayHaveFees={mayHaveFees}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
